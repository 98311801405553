<template>
  <XyzTransition
    appear
    xyz="fade left-3 delay-3"
  >
    <div class="sidebar-left">
      <div class="sidebar">
        <div class="sidebar-content todo-sidebar">
          <div class="todo-app-menu">
            <div class="add-task">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                block
                @click="$emit('update:is-task-handler-sidebar-active', true); $emit('close-left-sidebar')"
              >
                Task Baru
              </b-button>
            </div>
            <vue-perfect-scrollbar
              :settings="perfectScrollbarSettings"
              class="sidebar-menu-list scroll-area"
            >
              <!-- Filters -->
              <b-list-group class="list-group-filters">
                <b-list-group-item
                  v-for="filter in taskFilters"
                  :key="filter.title + $route.path"
                  :to="filter.route"
                  :active="isDynamicRouteActive(filter.route)"
                  @click="$emit('close-left-sidebar')"
                >
                  <feather-icon
                    :icon="filter.icon"
                    size="18"
                    class="mr-75"
                  />
                  <span class="align-text-bottom line-height-1">{{ filter.title }}</span>
                </b-list-group-item>
              </b-list-group>

              <!-- Tags -->
              <div class="mt-3 px-2 d-flex justify-content-between">
                <h6 class="section-label mb-1">
                  Tags
                </h6>
              </div>

              <div
                v-if="!taskTags.length"
                class="mt-1"
              >
                <div
                  v-for="index in 10"
                  :key="index"
                  class="d-flex align-items-center mb-2"
                >
                  <b-skeleton
                    type="avatar"
                    height="11px"
                    width="11px"
                    class="m-0 mr-1"
                    style="margin-left: 23px !important"
                  />
                  <b-skeleton
                    width="60%"
                    height="11px"
                    class="m-0"
                  />
                </div>
              </div>

              <b-list-group
                v-else
                class="list-group-labels"
              >
                <b-list-group-item
                  v-for="tag in taskTags"
                  :key="tag.title + $route.path"
                  :active="tagActive(tag)"
                  class="cursor-pointer"
                  @click="handleSelectedTag(tag)"
                >
                  <span
                    class="bullet bullet-sm mr-1"
                    :class="`bullet-${tag.color}`"
                  />
                  <span>{{ tag.title }}</span>
                </b-list-group-item>
              </b-list-group>

            </vue-perfect-scrollbar>
          </div>
        </div>
      </div>
    </div>
  </XyzTransition>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
  BButton, BListGroup, BListGroupItem, BSkeleton,
} from 'bootstrap-vue'
import { isDynamicRouteActive } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BListGroup,
    BListGroupItem,
    BSkeleton,
    VuePerfectScrollbar,
  },
  props: {
    taskTags: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedTags: [],
    }
  },
  computed: {
    taskFilters() {
      return [
        {
          title: 'Daftar Task',
          icon: 'ListIcon',
          route: {
            name: 'task-filter',
            params: { filter: 'unfinished' },
            query: this.$route.query,
          },
        },
        {
          title: 'Sudah Selesai',
          icon: 'CheckIcon',
          route: {
            name: 'task-filter',
            params: { filter: 'completed' },
            query: this.$route.query,
          },
        },
        {
          title: 'Semua',
          icon: 'ArchiveIcon',
          route: {
            name: 'task-filter',
            params: { filter: 'all' },
            query: this.$route.query,
          },
        },
      ]
    },
  },
  methods: {
    handleSelectedTag(tag) {
      if (!this.selectedTags.some(el => el.id === tag.id)) {
        this.selectedTags.push(tag)
      } else {
        this.selectedTags = this.selectedTags.filter(el => el.id !== tag.id)
      }

      this.$emit('close-left-sidebar')
      this.$emit('selected-tags', this.selectedTags)
    },
    tagActive(tag) {
      if (this.selectedTags.some(el => el.id === tag.id)) {
        return true
      }

      return false
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }

    return {
      perfectScrollbarSettings,
      isDynamicRouteActive,
    }
  },
}
</script>

<style>

</style>
