<template>
  <div>
    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      :visible="isTaskHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-task-handler-sidebar-active', val)"
      @hidden="resetForm"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <b-button
            v-if="taskData.id"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            size="sm"
            :variant="taskData.isCompleted ? 'outline-secondary' : 'outline-primary'"
            @click="checkTask()"
          >
            {{ taskData.isCompleted ? 'Aktifkan' : 'Tandai Sudah Selesai' }}
          </b-button>
          <h5
            v-else
            class="mb-0"
          >
            Task Baru
          </h5>
          <div>
            <feather-icon
              v-if="!expired"
              v-show="taskData.id"
              icon="Trash2Icon"
              class="cursor-pointer text-danger"
              size="20"
              @click="$emit('remove-task', taskData.id); hide();"
            />
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="submitTask"
          @reset.prevent="resetForm"
        >
          <div v-if="$root.role === 2">
            <div
              v-if="!taskData.id"
              class="d-flex justify-content-between align-items-center mb-1"
            >
              <small class="font-weight-bold">
                Task Untuk
              </small>
              <b-form-radio-group
                v-model="taskData.for"
                class="list item-view-radio-group w-75"
                size="sm"
                buttons
                button-variant="outline-primary"
              >
                <b-form-radio
                  value="me"
                >
                  Saya Sendiri
                </b-form-radio>
                <b-form-radio
                  value="other"
                >
                  Sales Lain
                </b-form-radio>
              </b-form-radio-group>
            </div>
            <div
              v-else
              class="mb-1"
            >
              <label for="user">Task Untuk</label>
              <h6 class="m-0">
                {{ taskData.user.name }}
              </h6>
            </div>
            <b-collapse :visible="taskData.for === 'other'">
              <div
                v-if="loadingUser"
                class="d-flex"
              >
                <b-skeleton
                  width="100%"
                  height="40px"
                  class="mb-0"
                />
              </div>
              <b-form-group
                v-else
                label="Pilih User"
                label-for="for-user"
              >
                <v-select
                  v-model="taskData.user"
                  class="w-100"
                  label="name"
                  :options="user"
                  placeholder="Pilih user"
                  :clearable="false"
                >
                  <template #option="data">
                    <div class="d-flex">
                      <b-avatar
                        :text="avatarText(data.name)"
                        variant="light-info"
                        class="mr-50"
                        size="36"
                        :src="data.photo ? data.photo : null"
                      />
                      <div>
                        <span class="font-weight-bold d-block">{{ data.name }}</span>
                        <small class="d-block">{{ data.role.name }}</small>
                      </div>
                    </div>
                  </template>
                </v-select>
              </b-form-group>
            </b-collapse>
          </div>
          <b-form-group
            label="Deskripsi"
            label-for="task-title"
          >
            <b-form-input
              id="task-title"
              v-model="taskData.deskripsi"
              autofocus
              trim
              placeholder="Deskripsi task"
            />
          </b-form-group>

          <b-collapse :visible="taskData.for === 'me' || taskData.user !== ''">
            <b-form-group
              v-if="!taskData.id"
              label="Customer"
              label-for="customer"
            >
              <customer-auto-suggest
                :key="taskData.user.id"
                v-model="taskData.customer"
                :filter-user="taskData.user.id"
                @onSelected="handleCustomerSelected"
              />
            </b-form-group>
          </b-collapse>

          <b-collapse
            v-if="taskData.customer"
            :visible="taskData.customer.id !== undefined"
          >
            <b-form-group
              label="Project"
              label-for="project"
            >
              <project-auto-suggest
                v-if="!taskData.id"
                id="project"
                :key="taskData.customer.id"
                v-model="taskData.project"
                :filter-customer="taskData.customer ? taskData.customer : null"
                @onSelected="handleProjectSelected"
              />
              <h6
                v-else
                class="m-0"
              >
                {{ taskData.project.name }}
              </h6>
            </b-form-group>
          </b-collapse>

          <b-form-group
            label="Tanggal Jatuh Tempo"
            label-for="due-date"
          >
            <flat-pickr
              v-if="!expired"
              v-model="taskData.dueDate"
              class="form-control"
              placeholder="Pilih tanggal"
              :config="{
                minDate: taskData.dueDate ? new Date(Date.parse(taskData.dueDate)) < new Date() ? taskData.dueDate : new Date() : new Date()
              }"
            />
            <h6
              v-else
              class="m-0"
            >
              {{ taskData.dueDate }}
            </h6>
          </b-form-group>

          <div class="d-flex justify-content-between">
            <small>Recurring</small>
            <small class="text-primary font-weight-bold">{{ taskData.recurring > 0 ? `${taskData.recurring} hari` : null }}</small>
          </div>
          <custom-spin-input
            v-model="taskData.recurring"
            class="mt-25"
            :max="365"
          />

          <!--Tag -->
          <b-form-group
            label="Tag"
            label-for="tag"
            class="mt-1"
          >
            <v-select
              v-model="taskData.tags"
              multiple
              :options="tagOptions"
              input-id="tags"
              label="name"
              placeholder="Pilih tag"
            />
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1 w-50"
              type="submit"
              :disabled="loadingAdd"
            >
              <b-spinner
                v-if="loadingAdd"
                variant="light"
                small
              />
              <span v-else>{{ taskData.id ? 'Update' : 'Tambah ' }}</span>
            </b-button>
            <b-button
              v-if="!taskData.id"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              class="w-50"
            >
              Reset
            </b-button>
          </div>
        </b-form>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton, BCollapse, BSpinner,
  BFormRadioGroup, BFormRadio, BSkeleton, BAvatar,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { required, email, url } from '@validations'
import { avatarText } from '@core/utils/filter'
import formValidation from '@core/comp-functions/forms/form-validation'
import { toRefs } from '@vue/composition-api'
import ProjectAutoSuggest from '@/components/input/ProjectAutoSuggest.vue'
import CustomerAutoSuggest from '@/components/input/CustomerAutoSuggest.vue'
import { mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CustomSpinInput from '@/components/input/CustomSpinInput.vue'
import useTaskHandler from './useTaskHandler'

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BCollapse,
    BSpinner,
    BFormRadioGroup,
    BFormRadio,
    BSkeleton,
    BAvatar,

    // 3rd party packages
    vSelect,
    flatPickr,

    // Custom component
    ProjectAutoSuggest,
    CustomerAutoSuggest,
    CustomSpinInput,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isTaskHandlerSidebarActive',
    event: 'update:is-task-handler-sidebar-active',
  },
  props: {
    isTaskHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    task: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      url,
      loadingAdd: false,
      taskData: {
        id: '',
        for: 'me',
        user: '',
        deskripsi: '',
        customer: {},
        project: {},
        tags: [],
        dueDate: '',
        isCompleted: false,
        recurring: 0,
      },
    }
  },
  computed: {
    ...mapGetters({
      tagOptions: 'getTagsList',
      user: 'getUserList',
      loadingUser: 'getLoadingUser',
    }),
    expired() {
      return this.taskData.id && new Date(Date.parse(this.taskData.dueDate)).setHours(23, 59, 59, 999) < new Date()
    },
  },
  watch: {
    taskData: {
      handler(data) {
        if (data.for === 'me') {
          this.taskData.user = ''
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (this.user.length < 1) {
      this.$store.dispatch('getAllUser', { filter: null })
    }
  },
  updated() {
    if (this.task.id && this.isTaskHandlerSidebarActive) {
      this.taskData = {
        id: this.task.id,
        deskripsi: this.task.description,
        user: this.task.user,
        customer: {
          id: this.task.project.customer_id.toString(),
        },
        project: this.task.project,
        tags: this.task.tags,
        dueDate: this.task.due_date,
        isCompleted: this.task.status === 1,
        recurring: this.task.recurring,
      }
    }
  },
  methods: {
    handleCustomerSelected(data) {
      this.taskData.customer = data
    },
    handleProjectSelected(data) {
      this.taskData.project = data
    },
    submitTask() {
      if (!this.taskData.project.id) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Pilih project terlebih dahulu!',
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
      } else {
        this.loadingAdd = true
        if (this.taskData.id) {
          this.$store.dispatch('updateTask', {
            id: this.taskData.id,
            description: this.taskData.deskripsi,
            project_id: this.taskData.project.id,
            recurring: this.taskData.recurring,
            due_date: this.taskData.dueDate ? new Date(this.taskData.dueDate).toISOString().slice(0, 10) : null,
            tags: this.taskData.tags.length > 0 ? this.taskData.tags.map(el => el.id) : null,
          }).then(() => {
            this.loadingAdd = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Berhasil mengupdate task!',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
            this.$emit('update:is-task-handler-sidebar-active', false)
            this.resetForm()
          }).catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Terjadi kesalahan',
                icon: 'EditIcon',
                variant: 'danger',
              },
            })
            this.loadingAdd = false
          })
        } else {
          this.$store.dispatch('addTask', {
            user_id: this.taskData.for === 'other' ? this.taskData.user.id : this.$store.getters.getCurrentUser.user.id,
            description: this.taskData.deskripsi,
            project_id: this.taskData.project.id,
            recurring: this.taskData.recurring,
            due_date: this.taskData.dueDate ? new Date(this.taskData.dueDate).toISOString().slice(0, 10) : null,
            tags: this.taskData.tags.length > 0 ? this.taskData.tags.map(el => el.id) : null,
          }).then(() => {
            this.loadingAdd = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Berhasil menambahkan task baru!',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
            this.$emit('update:is-task-handler-sidebar-active', false)
            this.resetForm()
          }).catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Terjadi kesalahan',
                icon: 'EditIcon',
                variant: 'danger',
              },
            })
            this.loadingAdd = false
          })
        }
      }
    },
    checkTask() {
      if (this.task.status === 1) {
        this.$emit('undone-task', this.task)
      } else {
        this.$emit('done-task', this.task)
      }
      this.$emit('update:is-task-handler-sidebar-active', false)
    },
    resetForm() {
      this.taskData = {
        id: '',
        for: 'me',
        user: '',
        deskripsi: '',
        customer: {},
        project: {},
        tags: [],
        dueDate: '',
        isCompleted: false,
        recurring: 0,
      }
      this.$emit('clear-task-data')
    },
  },
  setup(props, { emit }) {
    const {
      taskLocal,
      resetTaskLocal,

      // UI
      assigneeOptions,
    } = useTaskHandler(toRefs(props), emit)

    const {
      refFormObserver,
      getValidationState,
    } = formValidation(resetTaskLocal, props.clearTaskData)

    return {
      // Add New
      taskLocal,
      assigneeOptions,

      // Form Validation
      refFormObserver,
      getValidationState,

      // Filter/Formatter
      avatarText,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
    padding-left: 0;
  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>
