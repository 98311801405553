<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar = false"
    />
    <div class="todo-app-list">

      <!-- App Searchbar Header -->
      <XyzTransition
        appear
        xyz="fade up-3 delay-7"
      >
        <div class="app-fixed-search d-flex align-items-center">

          <!-- Toggler -->
          <div class="sidebar-toggle d-block d-lg-none ml-1">
            <feather-icon
              icon="MenuIcon"
              size="21"
              class="cursor-pointer"
              @click="mqShallShowLeftSidebar = true"
            />
          </div>

          <!-- Searchbar -->
          <div class="d-flex align-content-center justify-content-between w-100">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon
                  icon="SearchIcon"
                  class="text-muted"
                />
              </b-input-group-prepend>
              <b-form-input
                :value="watchQuery"
                placeholder="Cari task..."
                @input="updateRouteQuery"
              />
            </b-input-group>
          </div>

          <!-- Dropdown -->
          <div class="dropdown">
            <b-dropdown
              variant="link"
              no-caret
              toggle-class="p-0 mr-1"
              right
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                :active="!$route.query.sort"
                :to="{ name: $route.name }"
              >
                Sort Due Date Terbaru
              </b-dropdown-item>
              <b-dropdown-item
                :active="$route.query.sort === 'duedate-asc'"
                :to="{ name: $route.name, query: { ...$route.query, sort: 'duedate-asc' } }"
              >
                Sort Due Date Terlama
              </b-dropdown-item>
              <b-dropdown-item
                :active="$route.query.sort === 'title-asc'"
                :to="{ name: $route.name, query: { ...$route.query, sort: 'title-asc' } }"
              >
                Sort Nama Task A-Z
              </b-dropdown-item>
              <b-dropdown-item
                :active="$route.query.sort === 'duedate-desc'"
                :to="{ name: $route.name, query: { ...$route.query, sort: 'title-desc' } }"
              >
                Sort Nama Task Z-A
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </XyzTransition>

      <!-- Todo List -->
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="todo-task-list-wrapper list-group scroll-area"
      >
        <XyzTransitionGroup
          appear
          xyz="fade down-3 delay-5"
        >
          <draggable
            :key="1"
            v-model="tasks"
            handle=".draggable-task-handle"
            tag="ul"
            class="todo-task-list media-list"
          >
            <li
              v-for="task in filteredTasks"
              :key="task.id"
              class="todo-item"
              :class="{ 'completed': task.status === 1 }"
            >
              <div
                class="todo-title-wrapper"
                @click="handleTaskClick(task)"
              >
                <div class="todo-title-area">
                  <div class="title-wrapper">
                    <b-form-checkbox
                      :checked="task.status === 1"
                      @click.native.prevent.stop="task.status === 1 ? undoneTask(task) : doneTask(task)"
                    />
                    <div class="ml-50">
                      <span
                        class="d-block"
                        :class="task.status === 1 ? 'text-muted' : task.status === 0 && new Date(Date.parse(task.due_date)).setHours(23, 59, 59, 999) < new Date() ? 'text-danger font-weight-bold' : 'text-primary font-weight-bold'"
                      >
                        {{ task.description }}
                      </span>
                      <small
                        class="d-block cursor-pointer"
                        @click="$router.push({ name: 'project-detail', params: {id: task.project.id.toString()} })"
                      >{{ task.project ? task.project.name : '' }}<feather-icon icon="ArrowUpRightIcon" /></small>
                      <div
                        v-if="$root.role === 2 && task.user && task.user.id !== $store.getters.getCurrentUser.user.id"
                        class="d-flex mt-50"
                      >
                        <b-avatar
                          size="36"
                          variant="light-primary"
                          text="FI"
                          class="mr-50"
                        />
                        <div>
                          <router-link :to="{ name: 'user-detail', params: { id: task.user.id.toString() } }">
                            <h6 class="m-0 text-primary">
                              {{ task.user.name }}
                            </h6>
                          </router-link>
                          <small class="text-muted">{{ task.user.role.name }}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="d-flex flex-column-reverse flex-lg-column mb-1 mb-md-50">
                    <small class="ml-0 ml-lg-auto text-nowrap text-muted mt-50 mt-lg-0">{{ formatDate(task.due_date) }}</small>
                    <small class="ml-0 ml-lg-auto text-secondary mt-25">{{ task.project.tgl_reminder ? `Tgl Reminder Project : ${formatDate(task.project.tgl_reminder)}` : '' }}</small>
                  </div>
                  <div
                    class="todo-item-action mt-50"
                  >
                    <div class="badge-wrapper">
                      <b-badge
                        v-for="tag in task.tags"
                        :key="tag.id"
                        pill
                        :variant="`light-${tagsVariant[tag.id].variant}`"
                        class="text-capitalize"
                      >
                        {{ tag.name }}
                      </b-badge>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </draggable>
        </XyzTransitionGroup>
        <div
          v-if="filteredTasks.length < 1"
          class="no-results show pt-3"
        >
          <h5 class="text-muted">
            {{ tasksList.length > 0 ? 'Tidak Ditemukan 😞' : 'Belum ada task ✅' }}
          </h5>
        </div>
      </vue-perfect-scrollbar>
    </div>

    <!-- Task Handler -->
    <todo-task-handler-sidebar
      v-model="isTaskHandlerSidebarActive"
      :task="task"
      @clear-task-data="clearTaskData"
      @remove-task="deleteTask"
      @done-task="doneTask"
      @undone-task="undoneTask"
    />

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <todo-left-sidebar
        :task-tags="taskTags"
        :is-task-handler-sidebar-active.sync="isTaskHandlerSidebarActive"
        :class="{'show': mqShallShowLeftSidebar}"
        @close-left-sidebar="mqShallShowLeftSidebar = false"
        @success-add="addTaskHandler()"
        @selected-tags="filterTag"
      />
    </portal>

    <b-modal
      id="recurring-modal"
      ref="recurringModal"
      hide-header
      hide-footer
      hide-header-close
      centered
    >
      <div class="d-block text-center mt-1">
        <h3>Buat task {{ selectedRecurring.description }} lagi?</h3>
        <h6 class="mt-50">
          Ulangi task {{ selectedRecurring.description }} lagi untuk tanggal {{ formatDate(nextRecurring) }} ?
          <b-form-datepicker
            v-model="nextRecurring"
            button-only
            right
            locale="id-ID"
            aria-controls="example-input"
            button-variant="outline-primary"
            class="custom-date-picker"
            :min="new Date()"
          >
            <template #button-content>
              <span class="text-primary cursor-pointer mr-50">Ubah tanggal <feather-icon
                size="14"
                icon="CalendarIcon"
              /></span>
            </template>
          </b-form-datepicker>
        </h6>
      </div>
      <div class="d-flex justify-content-center mt-2 mb-1">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mr-1"
          @click="newRecurring(selectedRecurring)"
        >
          Buat Task
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-danger"
          @click="$bvModal.hide('recurring-modal')"
        >
          Tidak Perlu
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import store from '@/store'
import {
  ref, onUnmounted,
} from '@vue/composition-api'
import {
  BFormInput, BInputGroup, BInputGroupPrepend, BDropdown, BDropdownItem, BModal,
  BFormCheckbox, BBadge, BButton, BFormDatepicker, BAvatar,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import draggable from 'vuedraggable'
import { formatDate, avatarText } from '@core/utils/filter'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import { mapGetters } from 'vuex'
import moment from 'moment'
import Fuse from 'fuse.js'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import TodoLeftSidebar from './TodoLeftSidebar.vue'
import todoStoreModule from './todoStoreModule'
import TodoTaskHandlerSidebar from './TodoTaskHandlerSidebar.vue'

export default {
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BDropdown,
    BAvatar,
    BDropdownItem,
    BFormCheckbox,
    BBadge,
    BModal,
    BButton,
    BFormDatepicker,
    draggable,
    VuePerfectScrollbar,

    // App SFC
    TodoLeftSidebar,
    TodoTaskHandlerSidebar,
  },
  props: {
    openAddForm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      nextRecurring: new Date(),
      selectedRecurring: {},
      tagsVariant: {
        1: {
          variant: 'danger',
        },
        2: {
          variant: 'warning',
        },
        3: {
          variant: 'primary',
        },
        4: {
          variant: 'info',
        },
        5: {
          variant: 'info',
        },
        6: {
          variant: 'info',
        },
        7: {
          variant: 'info',
        },
        8: {
          variant: 'info',
        },
      },
      selectedTags: [],
    }
  },
  computed: {
    ...mapGetters({
      tasksList: 'getTaskList',
      loading: 'getLoadingTask',
      tags: 'getTagsList',
    }),
    taskTags() {
      return this.tags.map(el => ({
        id: el.id,
        title: el.name,
        color: this.tagsVariant[el.id].variant,
        route: {
          name: 'task-tag',
          params: { tag: el.name },
        },
      }))
    },
    watchQuery() {
      return this.$route.query.q
    },
    watchParams() {
      return this.$route.params
    },
    watchSortBy() {
      return this.$route.query.sort
    },
    filteredTasks() {
      let tasks = this.tasksList

      if (this.watchParams.filter === 'completed') {
        tasks = tasks.filter(el => el.status === 1)
      } else if (this.watchParams.filter === 'all') {
        tasks = this.tasksList
      } else if (this.watchParams.filter === 'unfinished') {
        tasks = tasks.filter(el => el.status === 0)
      }

      if (this.selectedTags.length) {
        const filteredTasks = []

        tasks.forEach(task => {
          task.tags.forEach(tasktag => {
            this.selectedTags.forEach(tag => {
              if (tasktag.id === tag.id && !filteredTasks.some(el => el.id === task.id)) {
                filteredTasks.push(task)
              }
            })
          })
        })

        tasks = filteredTasks
      }

      if (this.watchQuery) {
        const fuse = new Fuse(tasks, {
          keys: ['description', 'project.name', 'user.name'],
          shouldSort: true,
          findAllMatches: true,
        })
        tasks = this.watchQuery.length
          ? fuse.search(this.watchQuery).map(({ item }) => item).slice(0, 10)
          : tasks
      }

      if (this.watchSortBy === 'duedate-asc') {
        tasks = tasks.sort((a, b) => new Date(moment(a.due_date).format('YYYY-MM-DD')) - new Date(moment(b.due_date).format('YYYY-MM-DD')))
      }

      if (this.watchSortBy === 'title-asc') {
        tasks = tasks.sort((a, b) => a.description.localeCompare(b.description))
      }

      if (this.watchSortBy === 'title-desc') {
        tasks = tasks.sort((a, b) => b.description.localeCompare(a.description))
      }

      return tasks
    },
  },
  mounted() {
    this.$store.dispatch('getTask')
    this.$store.dispatch('getTags')

    if (this.openAddForm) {
      setTimeout(() => {
        this.isTaskHandlerSidebarActive = true
      }, 200)
    }
  },
  methods: {
    filterTag(tags) {
      this.selectedTags = tags
    },
    updateRouteQuery(val) {
      const currentRouteQuery = JSON.parse(JSON.stringify(this.$route.query))

      if (val) currentRouteQuery.q = val
      else delete currentRouteQuery.q

      this.$router.replace({ name: this.$route.name, query: currentRouteQuery })
    },
    doneTask(task) {
      this.$swal({
        title: 'Selesaikan Task?',
        text: 'Konfirmasi jika anda ingin menyelesaikan task',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'text-primary btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('doneTask', task.id).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Task berhasil diselesaikan!',
                icon: 'EditIcon',
                variant: 'success',
              },
            })

            if (task.recurring > 0) {
              this.selectedRecurring = task
              this.nextRecurring = task.due_date ? new Date(Date.parse(task.due_date) + (task.recurring * 8.64e+7)) : new Date(Date.now() + (task.recurring * 8.64e+7))
              this.$refs.recurringModal.show()
            }
          })
        }
      })
    },
    undoneTask(task) {
      this.$swal({
        title: 'Aktifkan Task?',
        text: 'Konfirmasi jika anda ingin mengembalikan task ini',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'text-primary btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('doneTask', task.id).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Task berhasil diaktifkan!',
                icon: 'EditIcon',
                variant: 'success',
              },
            })

            this.$router.replace({ name: 'task-filter', params: { filter: 'unfinished' } })
          })
        }
      })
    },
    newRecurring(task) {
      this.$store.dispatch('addTask', {
        user_id: this.$store.getters.getCurrentUser.user.id,
        description: task.description,
        project_id: task.project.id,
        recurring: task.recurring,
        due_date: new Date(Date.parse(this.nextRecurring)).toISOString().slice(0, 10),
        tags: task.tags.length > 0 ? task.tags.map(el => el.id) : null,
      }).then(() => {
        this.$refs.recurringModal.hide()
        this.selectedRecurring = {}
        this.nextRecurring = new Date()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Berhasil menambahkan recurring task baru!',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Terjadi kesalahan!',
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
      })
    },
    deleteTask(id) {
      this.$swal({
        title: 'Hapus Task?',
        text: 'Konfirmasi jika anda ingin menghapus task',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'text-danger btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('deleteTask', id).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Task berhasil dihapus!',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
          })
        }
      })
    },
  },
  setup() {
    const TODO_APP_STORE_MODULE_NAME = 'app-todo'

    // Register module
    if (!store.hasModule(TODO_APP_STORE_MODULE_NAME)) store.registerModule(TODO_APP_STORE_MODULE_NAME, todoStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TODO_APP_STORE_MODULE_NAME)) store.unregisterModule(TODO_APP_STORE_MODULE_NAME)
    })

    const tasks = ref([])

    const blankTask = {
      id: '',
      deskripsi: '',
      customer: {},
      project: {},
      tags: [],
      dueDate: '',
      isCompleted: false,
      recurring: 0,
    }
    const task = ref(JSON.parse(JSON.stringify(blankTask)))
    const clearTaskData = () => {
      task.value = JSON.parse(JSON.stringify(blankTask))
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 300,
    }

    const isTaskHandlerSidebarActive = ref(false)

    const resolveTagVariant = tag => {
      if (tag === 'team') return 'primary'
      if (tag === 'low') return 'success'
      if (tag === 'medium') return 'warning'
      if (tag === 'high') return 'danger'
      if (tag === 'update') return 'info'
      return 'primary'
    }

    const handleTaskClick = taskData => {
      task.value = taskData
      isTaskHandlerSidebarActive.value = true
    }

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    return {
      task,
      tasks,
      clearTaskData,
      perfectScrollbarSettings,

      // UI
      resolveTagVariant,
      isTaskHandlerSidebarActive,

      // Click Handler
      handleTaskClick,

      // Filters
      formatDate,
      avatarText,

      // Left Sidebar Responsive
      mqShallShowLeftSidebar,
    }
  },
}
</script>

<style lang="scss" scoped>
.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;

  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";

.custom-date-picker .btn {
  padding: 0;
  border: 0 !important;
}
</style>
